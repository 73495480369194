
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import useMediaQueriesStore from '@/store/media-queries';

export default defineComponent({
  name: 'img-block',
  props: {
    src: {
      type: String,
      required: true,
    },
    src2x: String,
    mobileSrc: String,
    mobileSrc2x: String,
    alt: String,
  },
  computed: {
    ...mapState(useMediaQueriesStore, ['lessVars']),
  },
  methods: {
    getSrcset({ x1, x2 }: { x1: string, x2: string }): string {
      const result = [`${x1} 1x`];
      if (x2) {
        result.push(`${x2} 2x`);
      }
      return result.join(', ');
    },
  },
});
