import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ROUTES_NAMES from '@/data/constants/routes-names';
import guard from '@/router/guard';
import PageDashboard from '@/pages/dashboard';
import PageEInvitations from '@/pages/e-invitations';
import PageDemoEInvitations from '@/pages/demo-e-invitations';
import PageAdmin from '@/pages/admin';
import PageAdminWeddings from '@/pages/admin-weddings';
import PageAdminPersonalization from '@/pages/admin-personalization';
import PageAuth from '@/pages/auth';
import PageInvitationsFeedback from '@/pages/invitations-feedback';
import PageStub from '@/pages/stub';
import PageInvitationsConstructor from '@/pages/invitations-constructor';

const routes: Array<RouteRecordRaw> = [
  {
    component: PageDashboard,
    path: '/users/',
    children: [
      {
        component: PageEInvitations,
        name: ROUTES_NAMES.MAIN,
        path: 'onboarding',
      },
    ],
  },
  {
    component: PageEInvitations,
    name: ROUTES_NAMES.E_INVITATIONS,
    path: '/e-invitations/:path/:personPath?',
    props: true,
  },
  {
    component: PageDemoEInvitations,
    name: ROUTES_NAMES.DEMO_E_INVITATIONS,
    path: '/demo-e-invitations/',
  },
  {
    component: PageAdmin,
    name: ROUTES_NAMES.ADMIN,
    path: '/admin',
    meta: {
      admin: true,
    },
    children: [
      {
        component: PageAdminWeddings,
        name: ROUTES_NAMES.ADMIN_WEDDINGS,
        path: 'weddings',
      },
      {
        component: PageAdminPersonalization,
        name: ROUTES_NAMES.ADMIN_PERSONALIZATION,
        path: 'personalization',
      },
      {
        component: PageInvitationsConstructor,
        name: ROUTES_NAMES.INVITATIONS_CONSTRUCTOR,
        path: 'invitations-constructor',
      },
    ],
  },
  {
    component: PageAuth,
    name: ROUTES_NAMES.AUTH,
    path: '/auth',
  },
  {
    component: PageInvitationsFeedback,
    name: ROUTES_NAMES.INVITATIONS_FEEDBACK,
    path: '/invitations-feedback/:path',
    props: true,
  },
  {
    component: PageStub,
    name: ROUTES_NAMES.STUB,
    path: '',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

guard(router);

export default router;
