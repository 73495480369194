
import { defineComponent } from 'vue';
import axios from 'axios';
import TextBlock from '@/blocks/text';
import ButtonBlock from '@/blocks/button';
import TableBlock from '@/blocks/table';
import {
  FormResult,
  FormResultField,
  PRESENCE_STATUSES,
} from '@/data/e-invitations/types';
import { Column } from '@/data/types/table';
import { Page } from '@/data/e-invitations/page';
import { BLOCKS_CODES } from '@/data/e-invitations/block-codes';
import ChoiceBlockOptions from '@/data/e-invitations/block-options/choice-block';
import PresenceBlockOptions from '@/data/e-invitations/block-options/presence-block';
import { invitationsFeedbackApi } from '@/firebase';
import { getFullName, getStaticUrl } from '@/tools';
import exportExcel from '@/tools/export-excel';

const PRESENCE_STATUS_CODE = 'presenceStatus';
const BEVERAGES_CODE = 'beverages';

type ExcelRows = string[][];

export default defineComponent({
  name: 'page-invitations-feedback',
  components: {
    TextBlock,
    ButtonBlock,
    TableBlock,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    PRESENCE_STATUS_CODE,
    results: {} as Record<string, FormResult>,
    isExcelDownloading: false,
    page: {} as Page,
    isLoading: false,
  }),
  async created() {
    this.isLoading = true;
    this.page = (await axios.get(getStaticUrl(`e-invitations/${this.path}/index.json`))).data;
    this.results = await invitationsFeedbackApi.getItems(this.path);
    this.isLoading = false;
  },
  computed: {
    fieldColumns(): Column[] {
      const { blocks } = this.page;
      if (!blocks) {
        return [];
      }
      const fieldsLabels: string[] = [];
      blocks.forEach((block) => {
        if (block.code === BLOCKS_CODES.CHOICE_BLOCK) {
          const options = block.options as ChoiceBlockOptions;
          options.blocks.forEach((block) => {
            fieldsLabels.push(block.resultsTableLabel || block.title);
          });
        }
      });
      return fieldsLabels.map((label: string, fieldIndex) => ({
        label,
        code: `field${fieldIndex}`,
        cellType: 'content',
      }));
    },
    columns(): Column[] {
      const result: Column[] = [];
      const firstResult = Object.values(this.results)[0];
      if (firstResult.guestPath) {
        result.push({
          code: 'path',
          cellType: 'content',
          label: 'Ссылка',
        });
      }
      result.push(
        {
          code: 'guests',
          cellType: 'content',
          label: 'Имена',
        },
        {
          code: PRESENCE_STATUS_CODE,
          cellType: 'fixed-width',
          label: 'Присутствие',
          width: '200px',
        },
      );
      if (this.hasBeveragesBlock) {
        result.push({
          code: BEVERAGES_CODE,
          cellType: 'fixed-width',
          label: 'Напитки',
          width: '200px',
        });
      }
      result.push(
        ...this.fieldColumns,
        {
          code: 'createdDate',
          cellType: 'fixed-width',
          label: 'Дата отправки',
          width: '200px',
        },
      );
      return result;
    },
    rows(): Record<string, unknown>[] {
      return Object.values(this.results).map((feedback: FormResult) => ({
        path: feedback.guestPath,
        guests: feedback.guests.map((guest) => getFullName(guest)).join('\n'),
        presenceStatus: feedback.presenceStatus,
        beverages: feedback.beverages?.map(({ name, count }) => `${name} (${count})`).join('\n'),
        createdDate: feedback.createdDate,
        ...(feedback.fields?.reduce((
          result: Record<string, string>,
          field: FormResultField,
          fieldIndex: number,
        ) => {
          // eslint-disable-next-line no-param-reassign
          result[`field${fieldIndex}`] = field.value?.map((valueElement) => (Array.isArray(valueElement)
            ? valueElement.join(', ')
            : valueElement)).join('\n') || '';
          return result;
        }, {}) || {}),
      }));
    },
    hasBeveragesBlock(): boolean {
      const { blocks } = this.page;
      return blocks.some(({ code }) => code === BLOCKS_CODES.BEVERAGES_BLOCK);
    },
    hasPresenceStatusBlock(): boolean {
      const { blocks } = this.page;
      return blocks.some(({ code }) => code === BLOCKS_CODES.PRESENCE_BLOCK);
    },
    beveragesSum(): Record<string, number> {
      if (!this.hasBeveragesBlock) {
        return {};
      }
      return Object
        .values(this.results)
        .reduce((accum, { beverages = [] }) => {
          beverages.forEach(({ name, count }) => {
            if (!accum[name]) {
              // eslint-disable-next-line no-param-reassign
              accum[name] = 0;
            }
            // eslint-disable-next-line no-param-reassign
            accum[name] += count;
          });
          return accum;
        }, {} as Record<string, number>);
    },
    beveragesColumns(): Column[] {
      return [
        {
          code: 'name',
          cellType: 'content',
          label: 'Название',
        },
        {
          code: 'count',
          cellType: 'content',
          label: 'Количество',
        },
      ];
    },
    beveragesRows(): Record<string, string | number>[] {
      return Object.entries(this.beveragesSum).map(([name, count]) => ({ name, count }));
    },
    beveragesExcelRows(): ExcelRows {
      if (!this.hasBeveragesBlock) {
        return [];
      }
      const result: ExcelRows = [];

      result.push(['Предпочетния по напиткам']);
      Object.entries(this.beveragesSum).forEach(([name, count]) => {
        result.push([name, String(count)]);
      });
      return result;
    },
    presenceStatusExcelRows(): ExcelRows {
      if (!this.hasPresenceStatusBlock) {
        return [];
      }
      const result: ExcelRows = [];

      result.push(['Присутствие']);
      this.presenceStatusRows.forEach(({ status, count }) => {
        result.push([status, String(count)]);
      });
      return result;
    },
    presenceStatusColumns(): Column[] {
      return [
        {
          code: 'status',
          cellType: 'content',
          label: 'Статус',
        },
        {
          code: 'count',
          cellType: 'content',
          label: 'Количество',
        },
      ];
    },
    presenceStatusRows(): { count: number, status: string }[] {
      if (!this.hasPresenceStatusBlock) {
        return [];
      }
      type StatusesCount = {
        [PRESENCE_STATUSES.ACCEPT]: number;
        [PRESENCE_STATUSES.DECLINE]: number;
        [PRESENCE_STATUSES.POSTPONE]?: number;
      }
      const statusesCount: StatusesCount = {
        [PRESENCE_STATUSES.ACCEPT]: 0,
        [PRESENCE_STATUSES.DECLINE]: 0,
      };
      const presenceBlock = this.page.blocks
        .find(({ code }) => code === BLOCKS_CODES.PRESENCE_BLOCK);
      if ((presenceBlock?.options as PresenceBlockOptions).allowPostponeStatus) {
        statusesCount[PRESENCE_STATUSES.POSTPONE] = 0;
      }
      Object.values(this.results).forEach((result) => {
        statusesCount[result.presenceStatus] += result.guests.length;
      });
      return Object.entries(statusesCount).map(([status, count]) => ({
        count,
        status: this.getPresenceStatus(status as PRESENCE_STATUSES),
      }));
    },
  },
  methods: {
    getPresenceStatus(presenceStatus: PRESENCE_STATUSES): string {
      const statusMap = {
        [PRESENCE_STATUSES.ACCEPT]: 'Да',
        [PRESENCE_STATUSES.DECLINE]: 'Нет',
        [PRESENCE_STATUSES.POSTPONE]: 'Позже отвечу',
      };
      return statusMap[presenceStatus];
    },
    async downloadExcelResults(): Promise<void> {
      this.isExcelDownloading = true;
      const content = [];
      content.push(this.columns.map(({ label }) => label));
      this.rows.forEach((row) => {
        const valuesArray = this.columns.map(({ code }) => {
          if (code === PRESENCE_STATUS_CODE) {
            return this.getPresenceStatus(row[code] as PRESENCE_STATUSES);
          }
          return row[code];
        });
        content.push(valuesArray);
      });
      if (this.hasPresenceStatusBlock) {
        content.push([]);
        content.push(...this.presenceStatusExcelRows);
      }
      if (this.hasBeveragesBlock) {
        content.push([]);
        content.push(...this.beveragesExcelRows);
      }
      await exportExcel({ name: 'Результаты опроса', content });
      this.isExcelDownloading = false;
    },
  },
});
