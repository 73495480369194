
import { defineComponent } from 'vue';
import 'firebaseui/dist/firebaseui.css';
import { mapActions } from 'pinia';
import { User } from 'firebase/auth';
import { start, destroy } from '@/firebase/auth-ui';
import ROUTER_NAMES from '@/data/constants/routes-names';
import useUserStore from '@/store/user';

export default defineComponent({
  name: 'page-auth',
  mounted() {
    const element = this.$refs.element as HTMLElement;
    start(element, this.onSuccess);
  },
  beforeUnmount() {
    destroy();
  },
  methods: {
    ...mapActions(useUserStore, ['setUser']),
    onSuccess(result: User): false {
      this.setUser(result);
      this.$router.push({ name: ROUTER_NAMES.ADMIN_WEDDINGS });
      return false;
    },
  },
});
