enum ROUTER_NAMES {
  MAIN = 'stub',
  E_INVITATIONS = 'e-invitations',
  DEMO_E_INVITATIONS = 'demo-e-invitations',
  STUB = 'stub',
  ADMIN = 'admin',
  ADMIN_WEDDINGS = 'admin-weddings',
  ADMIN_PERSONALIZATION = 'admin-personalization',
  AUTH = 'auth',
  INVITATIONS_FEEDBACK = 'INVITATIONS_FEEDBACK',
  INVITATIONS_CONSTRUCTOR = 'INVITATIONS_CONSTRUCTOR',
}

export default ROUTER_NAMES;
