import {
  push,
  ref,
  set,
  get,
  child,
  remove,
  Database,
} from 'firebase/database';
import getDb from '@/firebase/db';

class ApiHandler {
  db: Database;

  pathPrefix: string;

  constructor(pathPrefix: string) {
    this.db = getDb();
    this.pathPrefix = pathPrefix;
  }

  getFullPath(path: string | undefined): string {
    if (path) {
      return `${this.pathPrefix}/${path}`;
    }
    return this.pathPrefix;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async get({ path }: { path?: string } = {}): Promise<any> {
    const dbRef = ref(this.db);
    const fullPath = this.getFullPath(path);
    const snapshot = await get(child(dbRef, fullPath));
    return snapshot.val();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async push({ value, path }: { value: any, path: string }): Promise<void> {
    const fullPath = this.getFullPath(path);
    const postRef = push(ref(this.db, fullPath));
    await set(postRef, value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async set({ value, path }: { value: any, path: string }): Promise<void> {
    const fullPath = this.getFullPath(path);
    await set(ref(this.db, fullPath), value);
  }

  protected async remove({ path }: { path: string }): Promise<void> {
    const fullPath = this.getFullPath(path);
    await remove(ref(this.db, fullPath));
  }
}

export default ApiHandler;
