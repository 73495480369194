import ApiHandler from '@/firebase/api-handler';
import { InvitationPersonalizationItem } from '@/data/e-invitations/types';

class InvitationsPersonalizationApi extends ApiHandler {
  constructor() {
    super('invitations-personalization');
  }

  static getFullPath(weddingPath: string, personPath: string) {
    return `${weddingPath}/${personPath}`;
  }

  getList(path: string): Promise<Record<string, InvitationPersonalizationItem>> {
    return this.get({ path });
  }

  getItem(weddingPath: string, personPath: string): Promise<InvitationPersonalizationItem> {
    const path = InvitationsPersonalizationApi.getFullPath(weddingPath, personPath);
    return this.get({ path });
  }

  async create({ weddingPath, personPath, value }: {
    weddingPath: string;
    personPath: string;
    value: InvitationPersonalizationItem;
  }): Promise<void> {
    const path = InvitationsPersonalizationApi.getFullPath(weddingPath, personPath);
    await this.set({ path, value });
  }

  async delete(weddingPath: string, personPath: string): Promise<void> {
    const path = InvitationsPersonalizationApi.getFullPath(weddingPath, personPath);
    await this.remove({ path });
  }
}

const invitationsPersonalizationApi = new InvitationsPersonalizationApi();

export default invitationsPersonalizationApi;
