import { getDatabase, Database } from 'firebase/database';
import getApp from '@/firebase/app';

let db: Database;

export default function getDb(): Database {
  if (!db) {
    db = getDatabase(getApp());
  }
  return db;
}
