import { Router } from 'vue-router';
import ROUTES_NAMES from '@/data/constants/routes-names';
import useUserStore from '@/store/user';

export default function (router: Router): void {
  router.beforeEach(async (to, from, next) => {
    const { isAuthorized } = useUserStore();
    const isAdmin = to.matched.some(({ meta }) => meta.admin);
    if (isAdmin && !isAuthorized) {
      next({ name: ROUTES_NAMES.AUTH });
    } else if (to.name === ROUTES_NAMES.AUTH && isAuthorized) {
      next({ name: ROUTES_NAMES.ADMIN_WEDDINGS });
    } else {
      next();
    }
  });
}
