
import { defineComponent } from 'vue';
import { weddingsListApi } from '@/firebase';
import { WeddingDictionaryRecord, WeddingDictionary } from '@/data/e-invitations/types';
import InputBlock from '@/blocks/input';
import CheckboxBlock from '@/blocks/checkbox';
import ButtonBlock from '@/blocks/button';
import ROUTER_NAMES from '@/data/constants/routes-names';

export default defineComponent({
  name: 'page-admin-weddings',
  components: {
    InputBlock,
    CheckboxBlock,
    ButtonBlock,
  },
  data: () => ({
    ROUTER_NAMES,
    newWedding: {
      path: '',
      label: '',
      withPersonalization: false,
    } as WeddingDictionaryRecord,
    weddings: {} as WeddingDictionary,
  }),
  async created() {
    this.weddings = await weddingsListApi.getList() || {};
  },
  methods: {
    async submit() {
      await weddingsListApi.create(this.newWedding);
      const { path, label, withPersonalization } = this.newWedding;
      this.weddings[path] = { label, withPersonalization };
      this.newWedding = {
        path: '',
        label: '',
        withPersonalization: false,
      };
    },
    async deleteWedding(path: string) {
      await weddingsListApi.delete(path);
      delete this.weddings[path];
    },
  },
});
