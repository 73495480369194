import ApiHandler from '@/firebase/api-handler';
import { WeddingDictionaryRecord, WeddingDictionary } from '@/data/e-invitations/types';

class WeddingsListApi extends ApiHandler {
  constructor() {
    super('weddings-list');
  }

  getList(): Promise<WeddingDictionary> {
    return this.get();
  }

  async create(record: WeddingDictionaryRecord): Promise<void> {
    await this.set({
      path: record.path,
      value: {
        label: record.label,
        withPersonalization: record.withPersonalization,
      },
    });
  }

  async delete(path: string): Promise<void> {
    await this.remove({ path });
  }
}

const weddingsListApi = new WeddingsListApi();

export default weddingsListApi;
