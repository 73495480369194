
import { defineComponent } from 'vue';
import ROUTER_NAMES from '@/data/constants/routes-names';

export default defineComponent({
  name: 'page-admin',
  data: () => ({
    ROUTER_NAMES,
  }),
});
