import store2 from 'store2';
import { User } from 'firebase/auth';

const userKey = 'user';

export function getUser(): User | null {
  return store2.get(userKey) || null;
}

export function setUser(user: User): void {
  store2.set(userKey, user);
}

export function deleteUser(): void {
  store2.remove(userKey);
}
