
import { defineComponent, PropType } from 'vue';
import BeveragesBlockInterfaceOptions from '@/data/e-invitations/block-options/beverages-block';
import defaultOptions from '@/data/e-invitations/block-default-options/beverages-block';
import BlockWithBackground from '@/blocks/block-with-background';
import TextBlock from '@/blocks/text';
import InputHolder from '@/blocks/input-holder';
import SvgIcon from '@/blocks/svg-icon';
import {
  ContainerDirective as ContainerIntersectionAnimationDirective,
  ElementDirective as ElementIntersectionAnimationDirective,
} from '@/directives/intersection-enter-animation';
import { FormResult, Name, PRESENCE_STATUSES } from '@/data/e-invitations/types';
import { uniqueId } from '@/tools';

type Values = Record<string, number>;

export default defineComponent({
  inheritAttrs: false,
  name: 'beverages-block-inv',
  components: {
    BlockWithBackground,
    TextBlock,
    InputHolder,
    SvgIcon,
  },
  directives: {
    ContainerIntersectionAnimationDirective,
    ElementIntersectionAnimationDirective,
  },
  props: {
    options: {
      type: Object as PropType<BeveragesBlockInterfaceOptions>,
      required: true,
    },
    presenceStatus: String as PropType<PRESENCE_STATUSES>,
    guests: {
      type: Array as PropType<Name[]>,
      required: true,
    },
  },
  emits: ['publish-methods', 'withdraw-methods'],
  data: () => ({
    id: uniqueId(),
    values: {} as Values,
    isError: false,
  }),
  created() {
    this.$emit('publish-methods', {
      validateFields: this.validateFields,
      getFields: this.getFields,
      resetFields: this.resetFields,
      id: this.id,
    });
    this.setInitValues();
  },
  unmounted() {
    this.$emit('withdraw-methods', { id: this.id });
  },
  computed: {
    computedOptions(): BeveragesBlockInterfaceOptions {
      return { ...defaultOptions, ...this.options };
    },
    errors(): string[] {
      return this.isError ? ['Необходимо указать предпочтения каждого из гостей (обратите внимание, что счетчик напитка может быть больше единицы)'] : [];
    },
  },
  methods: {
    setInitValues(): void {
      this.values = this.computedOptions.beverages.reduce(
        (result, beverageName, beverageIndex) => ({ ...result, [beverageIndex]: 0 }), {},
      );
    },
    getBeverageCount(index: number): number {
      return this.values[index];
    },
    isIncreaseButtonDisabled(index: number): boolean {
      return this.values[index] === this.guests.length;
    },
    increaseBeverageCount(index: number): void {
      this.isError = false;
      this.values[index] += 1;
    },
    decreaseBeverageCount(index: number): void {
      this.values[index] -= 1;
    },
    validateFields(): boolean {
      if (this.presenceStatus !== PRESENCE_STATUSES.ACCEPT) {
        return true;
      }
      const beveragesSum = Object.values(this.values).reduce((sum, count) => sum + count, 0);
      const isValid = beveragesSum >= this.guests.length;
      if (!isValid) {
        this.isError = true;
      }
      return isValid;
    },
    getFields(): { beverages: FormResult['beverages'] } {
      const beverages = Object.entries(this.values).reduce((result, [id, count]) => {
        if (count > 0) {
          result.push({ name: this.computedOptions.beverages[Number(id)], count });
        }
        return result;
      }, [] as Required<FormResult>['beverages']);
      return { beverages };
    },
    resetFields() {
      this.setInitValues();
      this.isError = false;
    },
  },
  watch: {
    presenceStatus() {
      this.isError = false;
    },
    'options.beverages': {
      immediate: true,
      handler() {
        this.setInitValues();
      },
    },
  },
});
