
import { defineComponent } from 'vue';
import HeaderBlock from '@/blocks/header';

export default defineComponent({
  name: 'page-dashboard',
  components: {
    HeaderBlock,
  },
});
