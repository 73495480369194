import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-admin" }
const _hoisted_2 = { class: "page-admin__nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: _ctx.ROUTER_NAMES.ADMIN_WEDDINGS }
      }, {
        default: _withCtx(() => [
          _createTextVNode("Список свадеб")
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_router_link, {
        to: { name: _ctx.ROUTER_NAMES.ADMIN_PERSONALIZATION }
      }, {
        default: _withCtx(() => [
          _createTextVNode("Персонализация")
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_router_link, {
        to: { name: _ctx.ROUTER_NAMES.INVITATIONS_CONSTRUCTOR }
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Конструктор приглашений ")
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_router_view)
  ]))
}