import ApiHandler from '@/firebase/api-handler';
import { FormResult } from '@/data/e-invitations/types';

class InvitationsFeedbackApi extends ApiHandler {
  constructor() {
    super('invitations-feedback');
  }

  getItems(path: string): Promise<Record<string, FormResult>> {
    return this.get({ path });
  }

  async pushItem({ weddingPath, value }: { weddingPath: string, value: FormResult }) {
    await this.push({ value, path: weddingPath });
  }
}

const invitationsFeedbackApi = new InvitationsFeedbackApi();

export default invitationsFeedbackApi;
