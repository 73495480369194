import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "first-block-iota__content" }
const _hoisted_2 = { class: "first-block-iota__text-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "first-block-iota",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.splittedDate, (dateItem, dateItemIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: dateItemIndex,
            class: "first-block-iota__date-item"
          }, [
            _createVNode(_component_text_block, _mergeProps({ class: "first-block-iota__date-text" }, _ctx.computedOptions.dateStyles, { size: [140, 120] }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(dateItem), 1)
              ]),
              _: 2
            }, 1040),
            (dateItemIndex !== _ctx.splittedDate.length - 1)
              ? _withDirectives((_openBlock(), _createBlock(_component_svg_icon, _mergeProps({
                  key: 0,
                  class: "first-block-iota__date-icon"
                }, _ctx.computedOptions.iconStyles), null, 16)), [
                  [_directive_element_intersection_animation_directive, { tact: 9 }]
                ])
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_text_block, _mergeProps({ class: "first-block-iota__name" }, _ctx.computedOptions.namesStyles, { size: [30, 30] }), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.names[0].firstName), 1)
            ]),
            _: 1
          }, 16),
          _createVNode(_component_text_block, _mergeProps({ class: "first-block-iota__name" }, _ctx.computedOptions.ampersandStyles, { size: [30, 30] }), {
            default: _withCtx(() => [
              _createTextVNode(" & ")
            ]),
            _: 1
          }, 16),
          _createVNode(_component_text_block, _mergeProps({ class: "first-block-iota__name" }, _ctx.computedOptions.namesStyles, { size: [30, 30] }), {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.names[1].firstName), 1)
            ]),
            _: 1
          }, 16)
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}