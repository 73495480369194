import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "page-admin-weddings" }
const _hoisted_2 = { class: "page-admin-weddings__items-container" }
const _hoisted_3 = { class: "page-admin-weddings__new-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_block = _resolveComponent("button-block")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_input_block = _resolveComponent("input-block")!
  const _component_checkbox_block = _resolveComponent("checkbox-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weddings, (item, path) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item,
          class: "page-admin-weddings__item"
        }, [
          _createTextVNode(_toDisplayString(path) + " " + _toDisplayString(item.label) + " " + _toDisplayString(item.withPersonalization ? 'С персонализацией' : 'Без персонализации') + " ", 1),
          _createVNode(_component_button_block, {
            onClick: ($event: any) => (_ctx.deleteWedding(path))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Удалить")
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_router_link, {
            to: { name: _ctx.ROUTER_NAMES.INVITATIONS_FEEDBACK, params: { path } },
            target: "_blank"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_button_block, null, {
                default: _withCtx(() => [
                  _createTextVNode("Перейти на результаты")
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_input_block, {
          class: "page-admin-weddings__new-item-input",
          type: "type-2",
          placeholder: "Ссылка",
          value: _ctx.newWedding.path,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newWedding.path) = $event))
        }, null, 8, ["value"]),
        _createVNode(_component_input_block, {
          class: "page-admin-weddings__new-item-input",
          type: "type-2",
          placeholder: "Название",
          value: _ctx.newWedding.label,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newWedding.label) = $event))
        }, null, 8, ["value"]),
        _createVNode(_component_checkbox_block, {
          class: "page-admin-weddings__new-item-input",
          value: _ctx.newWedding.withPersonalization,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newWedding.withPersonalization) = $event))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Персонализация")
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _createVNode(_component_button_block, {
        class: "page-admin-weddings__new-button",
        submit: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode("Создать")
        ]),
        _: 1
      })
    ], 32)
  ]))
}