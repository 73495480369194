import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "img" }
const _hoisted_2 = ["media", "srcset"]
const _hoisted_3 = ["media", "srcset"]
const _hoisted_4 = ["src", "srcset", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("picture", _hoisted_1, [
    (_ctx.mobileSrc)
      ? (_openBlock(), _createElementBlock("source", {
          key: 0,
          media: _ctx.lessVars.mobile,
          srcset: _ctx.getSrcset({ x1: _ctx.mobileSrc, x2: _ctx.mobileSrc2x })
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("source", {
      media: _ctx.lessVars.desktopOnly,
      srcset: _ctx.getSrcset({ x1: _ctx.src, x2: _ctx.src2x })
    }, null, 8, _hoisted_3),
    _createElementVNode("img", {
      class: "img__img",
      src: _ctx.src,
      srcset: _ctx.getSrcset({ x1: _ctx.src, x2: _ctx.src2x }),
      alt: _ctx.alt
    }, null, 8, _hoisted_4)
  ]))
}