import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "first-block-epsilon__content" }
const _hoisted_2 = { class: "first-block-epsilon__names-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_block = _resolveComponent("text-block")!
  const _component_block_with_background = _resolveComponent("block-with-background")!
  const _directive_element_intersection_animation_directive = _resolveDirective("element-intersection-animation-directive")!
  const _directive_container_intersection_animation_directive = _resolveDirective("container-intersection-animation-directive")!

  return _withDirectives((_openBlock(), _createBlock(_component_block_with_background, {
    class: "first-block-epsilon",
    background: _ctx.computedOptions.background
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-epsilon__title" }, _ctx.computedOptions.titleStyles, { size: [35, 18] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.computedOptions.titleText), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 1 }]
        ]),
        _createElementVNode("div", _hoisted_2, [
          _withDirectives((_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(_ctx.bem('first-block-epsilon__name', { left: true }))
          }, [
            _createVNode(_component_text_block, _mergeProps({ class: "first-block-epsilon__first-name" }, _ctx.firstNameBind), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.names[0].firstName[0]), 1)
              ]),
              _: 1
            }, 16),
            _createVNode(_component_text_block, _normalizeProps(_guardReactiveProps(_ctx.surNameBind)), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.names[0].surName), 1)
              ]),
              _: 1
            }, 16)
          ], 2)), [
            [_directive_element_intersection_animation_directive, { tact: 2 }]
          ]),
          _withDirectives(_createElementVNode("div", {
            class: "first-block-epsilon__names-separator",
            style: _normalizeStyle({ backgroundColor: _ctx.computedOptions.namesSeparatorColor })
          }, null, 4), [
            [_directive_element_intersection_animation_directive, { tact: 3 }]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(_ctx.bem('first-block-epsilon__name', { right: true }))
          }, [
            _createVNode(_component_text_block, _mergeProps({ class: "first-block-epsilon__first-name" }, _ctx.firstNameBind), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.names[1].firstName[0]), 1)
              ]),
              _: 1
            }, 16),
            _createVNode(_component_text_block, _normalizeProps(_guardReactiveProps(_ctx.surNameBind)), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.names[1].surName), 1)
              ]),
              _: 1
            }, 16)
          ], 2)), [
            [_directive_element_intersection_animation_directive, { tact: 4 }]
          ])
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_text_block, _mergeProps({ class: "first-block-epsilon__date" }, _ctx.computedOptions.dateStyles, { size: [60, 36] }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.date)), 1)
          ]),
          _: 1
        }, 16)), [
          [_directive_element_intersection_animation_directive, { tact: 5 }]
        ])
      ])
    ]),
    _: 1
  }, 8, ["background"])), [
    [_directive_container_intersection_animation_directive]
  ])
}