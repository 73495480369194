import { defineStore } from 'pinia';
import { User } from 'firebase/auth';
import { getUser, setUser, deleteUser } from '@/tools/user-local-storage';

const useUserStore = defineStore('user', {
  state: () => ({
    user: getUser() as unknown as User | null,
  }),
  actions: {
    setUser(user: User | null) {
      this.user = user;
      if (user) {
        setUser(user);
      } else {
        deleteUser();
      }
    },
  },
  getters: {
    isAuthorized(state): boolean {
      return !!state.user;
    },
  },
});

export default useUserStore;
