
import { defineComponent } from 'vue';
import { weddingsListApi, invitationsPersonalizationApi } from '@/firebase';
import { WeddingDictionary, InvitationPersonalizationItem } from '@/data/e-invitations/types';
import SelectOption from '@/data/types/select-option';
import SelectBlock from '@/blocks/select';
import InputBlock from '@/blocks/input';
import ButtonBlock from '@/blocks/button';
import { getFullName } from '@/tools';

interface PersonalizationItemWithPath extends InvitationPersonalizationItem {
  path: string;
}

export default defineComponent({
  name: 'page-admin-personalization',
  components: {
    SelectBlock,
    InputBlock,
    ButtonBlock,
  },
  data: () => ({
    currentWedding: '',
    newGuest: {} as PersonalizationItemWithPath,
    weddings: {} as WeddingDictionary,
    guests: {} as Record<string, InvitationPersonalizationItem>,
  }),
  async created() {
    this.weddings = await weddingsListApi.getList() || {};
    this.resetNewGuest();
  },
  computed: {
    weddingsOptions(): SelectOption[] {
      return Object.entries(this.weddings).map(([weddingPath, wedding]) => ({
        label: `${weddingPath}:${wedding.label}`,
        value: weddingPath,
      }));
    },
    guestsForms(): SelectOption[] {
      return [
        {
          label: 'Он',
          value: 'he',
        },
        {
          label: 'Она',
          value: 'she',
        },
        {
          label: 'Они',
          value: 'they',
        },
        {
          label: 'Он (на Вы)',
          value: 'he-formal',
        },
        {
          label: 'Она (на Вы)',
          value: 'she-formal',
        },
      ];
    },
  },
  methods: {
    getFullName,
    resetNewGuest() {
      this.newGuest = {
        form: 'he',
        names: [{ firstName: '', surName: '' }],
        text: '',
        path: '',
      };
    },
    async submit() {
      const {
        path,
        names,
        text,
        form,
      } = this.newGuest;
      await invitationsPersonalizationApi.create({
        weddingPath: this.currentWedding,
        personPath: path,
        value: {
          form,
          text,
          names,
        },
      });
      this.guests[path] = { form, text, names };
      this.resetNewGuest();
    },
    async deleteGuest(path: string) {
      await invitationsPersonalizationApi.delete(this.currentWedding, path);
      delete this.guests[path];
    },
  },
  watch: {
    async currentWedding() {
      this.guests = await invitationsPersonalizationApi.getList(this.currentWedding) || {};
    },
  },
});
