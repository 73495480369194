import {
  getAuth as getAuthFirebase,
  EmailAuthProvider,
  Auth,
  User,
} from 'firebase/auth';
import * as firebaseUi from 'firebaseui';
import getApp from '@/firebase/app';

const config: firebaseUi.auth.Config = {
  signInOptions: [
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
    },
  ],
};
let authUi: firebaseUi.auth.AuthUI;
let auth: Auth;

export function getAuth(): Auth {
  if (!auth) {
    auth = getAuthFirebase(getApp());
  }
  return auth;
}

export function getAuthUi(): firebaseUi.auth.AuthUI {
  if (!authUi) {
    authUi = new firebaseUi.auth.AuthUI(getAuthFirebase(getApp()));
  }
  return authUi;
}

export function start(element: HTMLElement, callback: (authResult: User) => boolean): void {
  getAuthUi().start(element, {
    ...config,
    callbacks: {
      signInSuccessWithAuthResult: callback,
    },
  });
}

export async function destroy(): Promise<void > {
  await getAuthUi().delete();
}
